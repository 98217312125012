<script lang="ts" setup>
import type { PropType } from "vue";
import type { ISlide } from "~/lib/slide";

const props = defineProps({
    images: {
        type: Array as PropType<ISlide[]>,
        default: () => []
    }
});

const colLength = ref(0);

const slides = computed<Array<ISlide[]>>(() => {
    const firstColumn = props.images.filter((_, i) => i % 2 === 0);
    const secondColumn = props.images.filter((_, i) => i % 2 !== 0);
    colLength.value = firstColumn.length;
    return [firstColumn, secondColumn];
});

const preloadIndexes = computed(() => {
    const midIndex = Math.ceil(colLength.value / 2);
    const from = midIndex - 1 < 0 ? 0 : midIndex - 1;
    return [from, midIndex + 1];
});
</script>

<template>
    <div class="container">
        <div class="slider-wrapper">
            <div v-for="(column, i) in slides" :key="i" class="slider-column">
                <Image
                    v-for="(slide, j) in column"
                    :key="j"
                    hide-shining
                    class="slider"
                    :class="'slider_' + j + '_' + preloadIndexes[0] + '_' + preloadIndexes[1]"
                    :src="slide.src"
                    :alt="slide.alt"
                    sizes="100vw xs:210px lg:230px xl:310px"
                    styles="aspect-ratio: 7 / 11"
                    :loading="j >= preloadIndexes[0] && j <= preloadIndexes[1] ? undefined : 'lazy'"
                    :preload="j >= preloadIndexes[0] && j <= preloadIndexes[1]"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.container {
    position: absolute;
    inset: 0;
    right: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .slider-wrapper {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        rotate: -34deg;
        gap: 0.75rem;
        position: absolute;

        @media only screen and (min-width: 960px) {
            right: 8vw;
        }

        .slider-column {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: center;
            width: 100%;
            max-width: 12.5rem;
            gap: 0.75rem;

            @media only screen and (min-width: 1280px) {
                max-width: 14rem;
            }

            @media only screen and (min-width: 1920px) {
                max-width: 15.75rem;
            }

            animation: 120s infinite slide linear;

            &:last-of-type {
                animation-direction: reverse;
                // translate: 0 12.5rem;
            }

            &:hover {
                animation-play-state: paused;
            }

            .slider {
                & > div {
                    aspect-ratio: 3/5;
                }
            }
        }
    }
}

@keyframes slide {
    0%,
    50%,
    100% {
        transform: translateY(0%);
    }
    25% {
        transform: translateY(150%);
    }
    75% {
        transform: translateY(-150%);
    }
}
</style>
